<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A
        <number-value :value="sampleMixture" unit="" />
        sample of a mixture consisting solely of sodium bromide and potassium bromide yields
        <number-value :value="silverBromide" unit="\text{g}" />
        of silver bromide. Calculate the percentages by mass of the two salts in the sample.
        <chemical-latex content="" />
      </p>

      <calculation-input
        v-model="inputs.studentAnswerSodium"
        append-text="$\ce{\% sodium bromide}$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.studentAnswerPotassium"
        append-text="$\ce{\% potassium bromide}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A3_Q2',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerSodium: null,
        studentAnswerPotassium: null,
      },
    };
  },
  computed: {
    sampleMixture() {
      return this.taskState.getValueBySymbol('sampleMixture').content;
    },
    silverBromide() {
      return this.taskState.getValueBySymbol('silverBromide').content;
    },
  },
};
</script>
